// // services/axiosService.js
// import axios from 'axios';
// import { getToken } from './auth'; 

// const axiosInstance = axios.create({
//   baseURL: 'https://factures.fiduciairebrighten.com/api',
//   headers: {
//     'Content-Type': 'application/json',
//     'Accept': 'application/json'
//   }
// });

// axiosInstance.interceptors.request.use(function(config) {
//   const token = getToken();
//   config.headers.Authorization = token ? `Bearer ${token}` : '';
//   return config;
// }, function(error) {
//   return Promise.reject(error);
// });

// export default axiosInstance;
import axios from 'axios';
import { getToken } from './auth';

const axiosInstance = axios.create({
  baseURL: 'https://factures.fiduciairebrighten.com/api',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: true, // Ensure cookies are sent with requests
});

axiosInstance.interceptors.request.use(async function(config) {
  const token = getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : '';

  // Fetch CSRF token from cookies
  const csrfToken = document.cookie
    .split('; ')
    .find(row => row.startsWith('XSRF-TOKEN='))
    ?.split('=')[1];

  if (csrfToken) {
    config.headers['X-XSRF-TOKEN'] = decodeURIComponent(csrfToken);
  }

  return config;
}, function(error) {
  return Promise.reject(error);
});

export default axiosInstance;
